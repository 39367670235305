import React, { useState } from "react";

import { Link } from "gatsby";

import {
	MDBContainer,
	MDBNavbar,
	MDBNavbarBrand,
	MDBNavbarNav,
	MDBNavItem,
	MDBNavbarToggler,
	MDBCollapse,
} from "mdbreact";

import Logo from "../../../assets/images/img-logo.svg";

export default function MainNavbar() {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<MDBNavbar dark expand="md" className="bg-green" style={{paddingTop:"1rem", paddingBottom:"1rem"}}>
			<MDBContainer>
				<MDBNavbarBrand>
					<Link to="/">
						<img
							src="https://www.feevale.br/s/conteudo/b3af5fc4-f40d-42cd-bedb-86291657abf3/Feevale-selo55anos-bco.svg"
							className="img-fluid"
							style={{ width: 200 }}
							alt="Universidade Feevale"
							title="Universidade Feevale"
						/>
					</Link>
				</MDBNavbarBrand>

				<MDBNavbarToggler onClick={() => setIsOpen(!isOpen)} />
				<MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
					<MDBNavbarNav right className="text-center">
						<MDBNavItem className="px-3">
							<Link to="/" className="nav-link" style={{ fontWeight: 500 }}>
								Todos editais
							</Link>
						</MDBNavItem>
						<MDBNavItem className="px-3">
							<a
								href="https://www.feevale.br"
								className="nav-link"
								style={{ fontWeight: 500 }}
							>
								Retornar ao site da Universidade Feevale
							</a>
						</MDBNavItem>
					</MDBNavbarNav>
				</MDBCollapse>
				{/* <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
					<MDBNavbarNav right className="text-center">
						<MDBNavItem>
							<Link to="/editais" className="nav-link" style={{ fontWeight: 500 }}>
								Todos editais
							</Link>
						</MDBNavItem> 
						<MDBNavItem>
							<MDBFormInline waves>
								<div className="md-form my-0">
									<input
										className="form-control mr-sm-2"
										type="text"
										placeholder="Buscar"
										aria-label="Search"
									/>
								</div>
							</MDBFormInline>
						</MDBNavItem> 
					</MDBNavbarNav>
				</MDBCollapse> */}
			</MDBContainer>
		</MDBNavbar>
	);
}
